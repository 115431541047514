export const environment = {
  production: false,
  name: 'uat',
  box: {
    apiManagementBaseUrl: 'https://eastus-gen2-dev-aspose-reporting-boxapi.azurewebsites.net'
  },
  canoe: {
    apiManagementBaseUrl: 'https://eastus-gen2-dev-aspose-reporting-boxapi.azurewebsites.net'
  },
  entity: {
    apiManagementBaseUrl: 'https://eastus-gen2-dev-intacct-reference-api.azurewebsites.net'
  },
  idlt: {
    detokenizeResults: true,
    apiManagementBaseUrl: 'api/idlt'
  },
  report: {
    apiManagementBaseUrl: 'https://eastus-gen2-dev-report-api.azurewebsites.net'
  },
  authentication: true,
  msalConfig: {
    auth: {
      clientId: '219837bc-5acd-427f-9787-6004aaefe60d',
      authority: 'https://login.microsoftonline.com/9682ec85-29da-47af-af19-310310aeecf2/',
      redirectUri: 'https://uat-gen2verse.gen2fund.com/'
    },
  },
  apiConfig: {
    scopes: ['user.read'],
    uri: 'https://graph.microsoft.com/v1.0/me',
  },

  UserGroups: {
    document_data_extraction_admin: 'ff049026-4c41-4838-99a9-79e67752edbd',
    document_data_extraction: 'a2b7c59c-2add-4976-8ee0-cdbbe29a3f3a',
  
    idlt_connect: 'a81117ab-d6df-4727-91ba-d7a6ffdae27b',
  
    canoe_data_extraction: 'be984c65-7054-4cd5-b09d-1ef8f5e9a433',
  
    reports: '7c0003fb-077e-4697-b893-e0f0dcd186ee',
  }
};